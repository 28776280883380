import CheckIcon from "@/Images/cards_images/success.svg"
import { Information } from "../components/Information"
import { EDepositStatus, useStatus, components } from "@usher/pe-client-front-end-lib"
import { useHistory } from "react-router-dom"
import { LoadingAnimation } from "../components/LoadingAnimation"
import warningIcon from "@/Images/cards_images/warning.svg"

interface IStatusElementProps {
  confirmation: number
  title?: string
}
const Charged = ({ title }: { title?: string }) => {
  const history = useHistory()
  return (
    <>
      <img src={CheckIcon} alt="" className="notification__icon" />
      <h3 className="notification__title">{title ?? "Card Charged"}</h3>
      <p className="notification__text">Exchange has been done</p>
      <button
        className="btn-reset btn-sq btn-red buy-steps__butt"
        type="button"
        onClick={() => history.push("/client")}
      >
        GO TO MEMBER AREA
      </button>
    </>
  )
}

const Error = ({ msg, title }: { msg: string; title?: string }) => {
  return (
    <>
      <img src="/img/closeRed.svg" alt="" className="notification__icon" />
      <h3 className="notification__title">{title ?? "Error"}</h3>
      <p className="notification__text">{msg}</p>
    </>
  )
}
const Fullfilled = ({ title }: { title?: string }) => {
  return (
    <>
      <h3 className="notification__title">{title ?? "Exchange Completed"}</h3>
      <p className="notification__text">Exchange has been done</p>
    </>
  )
}

const Receive = ({ confirmation, title }: IStatusElementProps) => {
  return (
    <>
      <h3 className="notification__title">{title ?? "Received Crypto"}</h3>
      <p className="notification__text">Blockchain network confirmation: {confirmation}</p>
      <div style={{ maxWidth: "100%", textAlign: "left" }}>
        <div className="warning-info received-status" style={{ fontSize: 14 }}>
          <img src={warningIcon} alt="" />
          <div>
            <strong>Cryptocurrency successfully received, </strong>transaction is being processed.
            Processing time is usually no more than 20 minutes.
            <p>As soon as the payment is processed, you will receive an email.</p>
            <p>The standard working hours are:</p>
            <p>
              <div>Monday - Friday from 04:00 a.m to 11:00 p.m GMT</div>
              <div>Weekends - Saturday and Sunday - 06:00 a.m to 11:00 p.m GMT</div>
            </p>
            <p>
              If you have any questions, please contact support team &nbsp;
              <a href="mail:support@wizarbit.com">support@wizarbit.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

const Pending = ({ confirmation, title }: IStatusElementProps) => {
  return (
    <>
      {/* <img src="/img/pending.svg" alt="" className="notification__icon" /> */}
      <h3 className="notification__title">{title ?? "Exchange Pending"}</h3>
      <p className="notification__text">Blockchain network confirmation: {confirmation}</p>
      <div style={{ textAlign: "left", maxWidth: "90%" }}>
        <p className="notification__text">
          * The deposit will be credited to you card as soon as the transaction receives more than 6
          Blockchain network confirmations
        </p>
      </div>
    </>
  )
}

const StatusElements = (confirmation: number, msg?: string) => ({
  [EDepositStatus.Charged]: <Charged />,
  [EDepositStatus.CardIssued]: <Charged title={EDepositStatus.CardIssued} />,
  [EDepositStatus.ExCompleted]: <Fullfilled />,
  [EDepositStatus.OrderCard_Exchange]: <Fullfilled title={EDepositStatus.OrderCard_Exchange} />,
  [EDepositStatus.Pending]: <Pending title={EDepositStatus.Pending} confirmation={confirmation} />,
  [EDepositStatus.OrderCard_Pending]: (
    <Pending confirmation={confirmation} title={EDepositStatus.OrderCard_Pending} />
  ),
  [EDepositStatus.Received]: (
    <Receive title={EDepositStatus.Received} confirmation={confirmation} />
  ),
  [EDepositStatus.OrderCard_Received]: (
    <Receive title={EDepositStatus.OrderCard_Received} confirmation={confirmation} />
  ),
  [EDepositStatus.Error]: (
    <Error title={EDepositStatus.Error} msg={msg ?? "Exchange is not completed"} />
  ),
  [EDepositStatus.IssueError]: <Error title={EDepositStatus.IssueError} msg={msg ?? ""} />,
  [EDepositStatus.CardCharged_Error]: (
    <Error msg={msg ?? ""} title={EDepositStatus.CardCharged_Error} />
  ),
})

const Status = ({ isCreate }: { isCreate?: boolean }) => {
  const { status, loading, confirmation, fetchStatus, error } = useStatus()
  const isEnding =
    status === EDepositStatus.Charged ||
    status === EDepositStatus.Error ||
    status === EDepositStatus.CardCharged_Error ||
    status === EDepositStatus.CardIssued ||
    status === EDepositStatus.IssueError

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="buy-steps__form buy-steps__add-card">
          <div className="row">
            <div className="col-md-6">
              <div className="octagon octagon-whit block-table-octagon buy-steps__payment" style={{justifyContent: 'center'}}>
                <div className="notification_box">
                  <div className="notification_box text-center" style={{fontFamily: "ChakraPetch-Regular"}}>
                    {!isEnding && status != null && (
                      <components.Timer
                        format="ss"
                        count_down={15}
                        onUpdate={fetchStatus}
                        secText=""
                        render={(text) => <LoadingAnimation text={text} />}
                      />
                    )}
                    {loading ? <h6>Loading...</h6> : StatusElements(confirmation, error)[status]}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "none" }}></div>
            <Information isCreate={isCreate} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Status
